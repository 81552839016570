// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_BASE = '/';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_JOURNAL = '/journal';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
  register: (useremail, usertypeId, auth) => path(ROOTS_AUTH, `/register/${useremail}/${usertypeId}/${auth}`),
  reviewerInvitation: (email, paperid) => path(ROOTS_AUTH, `/reviewer/invitation/${email}/${paperid}`),
  editorInvitation: (email) => path(ROOTS_AUTH, `/editor/invitation/${email}`),
};

export const PATH_BASE = {
  root: ROOTS_BASE,
}

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  surveyForm: (id) => `/survey-form/${id}`,
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  landing: {
    landing: path(ROOTS_DASHBOARD, '/landing'),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    createpublisher: (id) => path(ROOTS_DASHBOARD, `/user/createpublisher/${id}`),
    editpublisher: (id) => path(ROOTS_DASHBOARD, `/user/editpublisher/${id}`),
  },
  tasks: {
    root: path(ROOTS_DASHBOARD, '/tasks'),
    new: path(ROOTS_DASHBOARD, '/tasks/new'),
    list: path(ROOTS_DASHBOARD, '/tasks/list'),
    cards: path(ROOTS_DASHBOARD, '/tasks/cards'),
    profile: path(ROOTS_DASHBOARD, '/tasks/profile'),
    account: path(ROOTS_DASHBOARD, '/tasks/account'),
    edit: (id) => path(ROOTS_DASHBOARD, `/tasks/${id}/edit`),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    confirmation: (id, paperid) => path(ROOTS_DASHBOARD, `/e-commerce/confirmation/${id}/${paperid}`),
    checkout: path(ROOTS_DASHBOARD, `/e-commerce/checkout`),
    new: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/e-commerce/product/${id}`),
    edit: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    demoView: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
  },
  admin: {
    root: path(ROOTS_DASHBOARD, '/admin'),
    conference: path(ROOTS_DASHBOARD, '/admin/conference'),
    viewConference: (id) => path(ROOTS_DASHBOARD, `/admin/conference/${id}`),
    editConference: (id) => path(ROOTS_DASHBOARD, `/admin/conference/${id}/edit`),
    journal: path(ROOTS_DASHBOARD, '/admin/journal'),
    viewJournal: (id) => path(ROOTS_DASHBOARD, `/admin/journal/${id}`),
    editJournal: (id) => path(ROOTS_DASHBOARD, `/admin/journal/${id}/edit`),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/list/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/invoice/list/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/invoice/list/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    new: path(ROOTS_DASHBOARD, '/blog/new'),
    view: (title) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  },
  authorSubmission: {
    root: path(ROOTS_DASHBOARD, '/authorSubmission/dashboard'),
    journal: path(ROOTS_DASHBOARD, '/authorSubmission/journal'),
    manuscript: path(ROOTS_DASHBOARD, '/authorSubmission/manuscript'),
    new: path(ROOTS_DASHBOARD, '/authorSubmission/manuscript/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/authorSubmission/manuscript/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/authorSubmission/manuscript/${id}/edit`),
  },
  chiefEditor: {
    root: path(ROOTS_DASHBOARD, '/chiefEditor/dashboard'),
    taskAssign: path(ROOTS_DASHBOARD, '/chiefEditor/taskAssign'),
    usermanagement: path(ROOTS_DASHBOARD, '/chiefEditor/usermanagement'),
    userdetails: (userid) => path(ROOTS_DASHBOARD, `/chiefEditor/userdetails/${userid}`),
    view: path(ROOTS_DASHBOARD, `/chiefEditor/listing`),
  },
  reviewer: {
    root: path(ROOTS_DASHBOARD, '/reviewer/app'),
    manuscript: path(ROOTS_DASHBOARD, '/reviewer/manuscript'),
    details: (id) => path(ROOTS_DASHBOARD, `/reviewer/manuscript/${id}`),
    invitation: (email, paperid) => path(ROOTS_DASHBOARD, `/reviewer/invitation/${email}/${paperid}`),
  },
  editor: {
    root: path(ROOTS_DASHBOARD, '/editor/dashboard'),
    manuscriptlist: path(ROOTS_DASHBOARD, '/editor/ManuscriptListing'),
    manuscriptdetail: (taskid, paperid) => path(ROOTS_DASHBOARD, `/editor/ManuscriptDetail/${taskid}/${paperid}`),
    invitation: (email) => path(ROOTS_DASHBOARD, `/editor/invitation/${email}`),
  },
  transaction: {
    root: path(ROOTS_DASHBOARD, '/transaction'),
    list: path(ROOTS_DASHBOARD, '/transaction/list'),
    new: path(ROOTS_DASHBOARD, '/transaction/list/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/transaction/list/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/transaction/list/${id}/edit`),
  },
  userPublisherConferenceListing: {
    view: (id) => path(ROOTS_DASHBOARD, `/userPublisherConferenceListing/view/${id}`),
  },
};

export const PATH_JOURNAL_MANAGEMENT = {
  root: ROOTS_JOURNAL,
  journal: {
    root: path(ROOTS_JOURNAL, '/journal-management'),
    listcv: path(ROOTS_JOURNAL, '/journal-management/journal-cv-event'),
    history: path(ROOTS_JOURNAL, '/journal-management/history'),
    shop: path(ROOTS_JOURNAL, '/journal-management/shop'),
    view: (id) => path(ROOTS_JOURNAL, `/journal-management/shop/${id}`),
  },
  survey: {
    root: path(ROOTS_JOURNAL, '/survey-management'),
    form: path(ROOTS_JOURNAL, '/survey-management/form'),
    view: (id) => path(ROOTS_JOURNAL, `/survey-management/form/${id}`),
    edit: (id) => path(ROOTS_JOURNAL, `/survey-management/form/${id}/edit`),
  },
  setting: {
    root: path(ROOTS_JOURNAL, '/settings'),
  },
};

export const PATH_DOCS = {
  root: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
};

export const PATH_ZONE_ON_STORE = 'https://mui.com/store/items/zone-landing-page/';

export const PATH_MINIMAL_ON_STORE = 'https://mui.com/store/items/minimal-dashboard/';

export const PATH_FREE_VERSION = 'https://mui.com/store/items/minimal-dashboard-free/';

export const PATH_FIGMA_PREVIEW =
  'https://www.figma.com/file/rWMDOkMZYw2VpTdNuBBCvN/%5BPreview%5D-Minimal-Web.26.11.22?node-id=0%3A1&t=ya2mDFiuhTXXLLF1-1';
