import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import MainLayout from '../layouts/main';
// import SimpleLayout from '../layouts/simple';
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
//
import {
  // Auth
  LoginPage,
  RegisterPage,
  VerifyCodePage,
  NewPasswordPage,
  ResetPasswordPage,
  UserListPage,
  UserEditPage,
  UserCardsPage,
  UserCreatePage,
  UserProfilePage,
  UserAccountPage,
  UserCreatePublisherPage,
  UserEditPublisherPage,
  EcommerceCheckoutPage,
  EcommerceProductDetailsPage,
  InvoiceListPage,
  InvoiceDetailsPage,
  InvoiceCreatePage,
  InvoiceEditPage,
  AuthorDashboard,
  AuthorJournalList,
  AuthorManuscriptList,
  AuthorCreateManuscript,
  JournalDetailsPage,
  EditorDashboard,
  ManuscriptList,
  ManuscriptDetail,
  EditorInvitation,
  ChiefEditorDashboard,
  ChiefEditorViewTaskAssign,
  UserManagement,
  UserDetails,
  ReviewerDashboard,
  ReviewerManuscriptList,
  ManuscriptDetailsPage,
  ReviewerInvitation,
  ConferenceEventManagementIndexPage,
  ConferenceEventManagementCardViewIndexPage,
  HistoryConferenceEventManagementCardViewIndexPage,
  ConferenceEventManagementForm,
  JournalManagementIndexPage,
  SurveyManagementIndexPage,
  SurveyForm,
  SettingManagement,
  ConferenceShopPage,
  ConferencePaperSubmission,
  ConferencePaperList,
  ConferencePaperDetailsPage,
  ConferenceShopDetailsPage,
  JournalsCardDetail,
  JournalManagementCardViewIndexPage,
  MarketingLandingPage,
  SurveyFormPage,
  TransactionListPage,
  TransactionDetailsPage,
  OrganizerTransactionDetailsPage,
  AdminConferenceListPage,
  AdminConferenceDetailsPage,
  AdminConferenceEditPage,
  AdminJournalListPage,
  AdminJournalDetailsPage,
  AdminJournalEditPage,
  ConferenceAssistantInvitation,
  JournalAssistantInvitation,
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Main Routes
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { path: '', element: <MarketingLandingPage /> },
      ],
    },
    // Auth
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: 'register/:useremail/:usertypeId/:auth',
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
        { path: 'reviewer/invitation/:email/:paperid', element: <ReviewerInvitation /> },
        { path: 'editor/invitation/:email', element: <EditorInvitation /> },
        { path: 'login-unprotected', element: <LoginPage /> },
        {
          path: '',
          element: <CompactLayout />,
          children: [
            { path: 'reset-password', element: <ResetPasswordPage /> },
            { path: 'new-password', element: <NewPasswordPage /> },
            { path: 'verify', element: <VerifyCodePage /> },
          ],
        },
      ],
    },

    // Dashboard
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: 'admin',
          children: [
            { element: <Navigate to="/dashboard/admin/journal" replace />, index: true },
            // { path: 'journal', element: <AdminConferenceListPage /> },
            // { path: 'journal/:id', element: <AdminConferenceDetailsPage /> },
            // { path: 'journal/:id/edit', element: <AdminConferenceEditPage /> },
            { path: 'journal', element: <AdminJournalListPage /> },
            { path: 'journal/:id', element: <AdminJournalDetailsPage /> },
            { path: 'journal/:id/edit', element: <AdminJournalEditPage /> },
          ],
        },
        {
          path: 'e-commerce',
          children: [
            { element: <Navigate to="/dashboard/e-commerce/shop" replace />, index: true },
            { path: 'confirmation/:id/paperid', element: <EcommerceCheckoutPage /> },
            { path: 'product/:id', element: <EcommerceProductDetailsPage /> },
            { path: 'checkout', element: <EcommerceCheckoutPage /> },
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'profile', element: <UserProfilePage /> },
            { path: 'cards', element: <UserCardsPage /> },
            { path: 'list', element: <UserListPage /> },
            { path: 'new', element: <UserCreatePage /> },
            { path: ':id/edit', element: <UserEditPage /> },
            { path: 'account', element: <UserAccountPage /> },
            { path: 'createpublisher/:id', element: <UserCreatePublisherPage /> },
            { path: 'editpublisher/:id', element: <UserEditPublisherPage /> },
          ],
        },
        {
          path: 'invoice',
          children: [
            { element: <Navigate to="/dashboard/invoice/list" replace />, index: true },
            { path: 'list', element: <InvoiceListPage /> },
            { path: 'list/:id', element: <InvoiceDetailsPage /> },
            { path: 'list/:id/edit', element: <InvoiceEditPage /> },
            { path: 'list/new', element: <InvoiceCreatePage /> },
          ],
        },
        {
          path: 'authorSubmission',
          children: [
            { element: <Navigate to="/dashboard/authorSubmission/manuscript" replace />, index: true },
            { path: 'dashboard', element: <AuthorDashboard /> },
            { path: 'manuscript', element: <AuthorManuscriptList /> },
            { path: 'manuscript/:id', element: <JournalDetailsPage /> },
            { path: 'journal', element: <AuthorJournalList /> },
            { path: 'manuscript/:id', element: <JournalDetailsPage /> },
            { path: 'manuscript/new', element: <AuthorCreateManuscript /> },
            { path: 'manuscript/:id/edit', element: <AuthorCreateManuscript /> },
          ],
        },
        {
          path: 'chiefEditor',
          children: [
            { element: <UserManagement />, index: true },
            { path: 'dashboard', element: <ChiefEditorDashboard /> },
            { path: 'taskAssign', element: <ChiefEditorViewTaskAssign /> },
            { path: 'usermanagement', element: <UserManagement /> },
            { path: 'userdetails/:userid', element: <UserDetails /> },
          ],
        },
        {
          path: 'reviewer',
          children: [
            { element: <Navigate to="/dashboard/reviewer/manuscript" replace />, index: true },
            { path: 'app', element: <ReviewerDashboard /> },
            { path: 'manuscript', element: <ReviewerManuscriptList /> },
            { path: 'manuscript/:id', element: <ManuscriptDetailsPage /> },
            { path: 'invitation/:email/:paperid', element: <ReviewerInvitation /> },
          ],
        },
        {
          path: 'editor',
          children: [
            { element: <Navigate to="/dashboard/editor/ManuscriptListing" replace />, index: true },
            { path: 'dashboard', element: <EditorDashboard /> },
            { path: 'ManuscriptListing', element: <ManuscriptList /> },
            { path: 'ManuscriptDetail/:taskid/:paperid', element: <ManuscriptDetail /> },
            { path: 'invitation/:email', element: <EditorInvitation /> },
          ],
        },
        {
          path: 'transaction',
          children: [
            { element: <Navigate to="/dashboard/transaction/list" replace />, index: true },
            { path: 'list', element: <TransactionListPage /> },
            { path: 'list/:id', element: <TransactionDetailsPage /> },
          ],
        },
        {
          path: 'userPublisherConferenceListing',
          children: [
            { element: <Navigate to="/dashboard/userPublisherConferenceListing/" replace />, index: true },
            { path: 'view/:id', element: <JournalsCardDetail /> },
          ],
        },
      ],
    },
    {
      path: 'journal', // the main path to the journal management, type /DJMS will redirect to the index page
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <ConferenceEventManagementIndexPage />, index: true },
        {
          path: 'journal-management',
          children: [
            { element: <Navigate to="/journal/journal-management/journal" replace />, index: true },
            { path: 'journal-cv-event', element: <JournalManagementCardViewIndexPage /> },
            { path: 'journal', element: <JournalManagementIndexPage /> },
            { path: 'shop', element: <ConferenceShopPage /> },
            { path: 'shop/:id', element: <ConferenceShopDetailsPage page="shop" /> },
          ],
        },
        {
          path: 'survey-management',
          children: [
            { element: <Navigate to="/journal/survey-management/survey" replace />, index: true },
            { path: 'survey', element: <SurveyManagementIndexPage /> },
            { path: 'form', element: <SurveyForm /> },
            { path: 'form/:id', element: <SurveyForm /> },
            { path: 'form/:id/edit', element: <SurveyForm /> },
          ],
        },
        {
          path: 'settings',
          children: [
            { element: <Navigate to="/journal/settings/general-setting" replace />, index: true },
            { path: 'general-setting', element: <SettingManagement /> },
          ],
        },
      ],
    },
    {
      path: 'survey-form/:id',
      element: <SurveyFormPage />,
    },
    {
      path: 'journal-details',
      element: <MainLayout />,
      children: [
        { path: ':id', element: <ConferenceShopDetailsPage page="invitation"/> },
      ],
    },
    {
      path: 'journal-assistant-invitation/:email/:id/:roleId/:hostId',
      element: <ConferenceAssistantInvitation />,
    },
    {
      path: 'journal-assistant-invitation/:email/:id/:roleId/:hostId',
      element: <JournalAssistantInvitation />,
    },
  ]);
}
