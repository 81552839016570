import { createSlice } from '@reduxjs/toolkit';
// utils
import { ServerConfiguration } from '../../utils/serverConfig';

// ----------------------------------------------------------------------
const url = ServerConfiguration.testingServerUrl;
const initialState = {
    isLoading: false,
    error: null,

    // View Data
    publishers: [],
    publishersStatus: [],
    createPublisher: [],
    updatepublisher1: [],
    updatepublisher2: [],
    confirmUpdatePublisher: [],
    deleteJournal: [],
    addUserAccess: [],
    getUserAccess: [],
    deleteUserAccess: [],
};

const slice = createSlice({
    name: 'publisher',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        getJournalSuccess(state, action) {
            state.isLoading = false;
            state.publishers = action.payload;
        },

        getJournalStatusSuccess(state, action) {
            state.isLoading = false;
            state.publishersStatus = action.payload;
        },
        // Create New Journal
        addJournalPricingSuccess(state, action) {
            state.isLoading = false;
            state.createPublisher = action.payload;
        },
        // Create New Journal Step 1
        updateJournalSuccess(state, action) {
            state.isLoading = false;
            state.updatepublisherData = action.payload;
        },
        // // Create New Journal Step 1
        // updateJournalBasicInfoSuccess(state, action) {
        //     state.isLoading = false;
        //     state.updatepublisher1 = action.payload;
        // },
        // // Create New Journal Step 2
        // updateJournalPricingSuccess(state, action) {
        //     state.isLoading = false;
        //     state.updatepublisher2 = action.payload;
        // },
        // Create New Journal Confirmation
        updateJournalConfirmationSuccess(state, action) {
            state.isLoading = false;
            state.confirmUpdatePublisher = action.payload;
        },

        updateJournalStatusSuccess(state, action) {
            state.isLoading = false;
            state.confirmUpdatePublisher = action.payload;
        },

        // Create New Journal Confirmation
        deleteJournalSuccess(state, action) {
            state.isLoading = false;
            state.deleteJournal = action.payload;
        },

        // Add Journal User Access
        addJournalUserAccessSuccess(state, action) {
            state.isLoading = false;
            state.addUserAccess = action.payload;
        },

        // View Journal User Access
        getJournalUserAccessSuccess(state, action) {
            state.isLoading = false;
            state.getUserAccess = action.payload;
        },

        // Delete Journal User Access
        deleteJournalUserAccessSuccess(state, action) {
            state.isLoading = false;
            state.deleteUserAccess = action.payload;
        },

        clearJournalDataSuccess(state, action) {
            state.isLoading = false;
            state.createPublisher = [];
            state.publishers = [];
            state.updatepublisher1 = [];
            state.deleteJournal = [];
            state.addUserAccess = [];
            state.deleteUserAccess = [];
            state.confirmUpdatePublisher = [];
        },
        unsubscribeJournal(state, action) {
            state.isLoading = false;
            state.publishers = [];
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    getConferenceEventsSuccess,
} = slice.actions;

// Add Journal
export function Journal_AddJournalWithPricingID(JOURNALPRICINGID, CREATEDBY) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());

        try {
            const response = await fetch(
                `${url}Journal_AddJournalWithPricingID?JOURNALPRICINGID=${JOURNALPRICINGID}&CREATEDBY=${CREATEDBY}`
            )
            const json = await response.json();
            const data = JSON.parse(json)[0];
            if (data && data.ReturnVal === 1) {
                dispatch(slice.actions.addJournalPricingSuccess(JSON.parse(data.ReturnData)));
            } else {
                dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// Update Journal Basic Profile
export function Journal_UpdateJournal_Stage1(JOURNALID, JOURNALNAME, JOURNALDESCRIPTION, JOURNALREGISTRATIONNO, JOURNALEMAIL, JOURNALADDRESS, JOURNALCOUNTRYID, JOURNALLOGO, JOURNALGUIDELINE, JOURNALTOPICNAME, REVIEWTYPE, REVIEWERNUMBER, MODIFIEDBY) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());

        try {
            const response = await fetch(
                `${url}Journal_UpdateJournal_Stage1?JOURNALID=${JOURNALID}&JOURNALNAME=${JOURNALNAME}&JOURNALDESCRIPTION=${JOURNALDESCRIPTION}&JOURNALREGISTRATIONNO=${JOURNALREGISTRATIONNO}&JOURNALEMAIL=${JOURNALEMAIL}&JOURNALADDRESS=${JOURNALADDRESS}&JOURNALCOUNTRYID=${JOURNALCOUNTRYID}&JOURNALLOGO=${JOURNALLOGO}&JOURNALGUIDELINE=${JOURNALGUIDELINE}&JOURNALTOPICNAME=${JOURNALTOPICNAME}&REVIEWTYPE=${REVIEWTYPE}&REVIEWERNUMBER=${REVIEWERNUMBER}&MODIFIEDBY=${MODIFIEDBY}`
            )

            const json = await response.json();
            const data = JSON.parse(json)[0];
            if (data && data.ReturnVal === 1) {
                dispatch(slice.actions.updateJournalSuccess(JSON.parse(data.ReturnData)));
            } else {
                dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// Update Journal Pricing
export function Journal_UpdateJournal_Stage2(JOURNALID, JOURNALPRICINGID, MODIFIEDBY) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());

        try {
            const response = await fetch(
                `${url}Journal_UpdateJournal_Stage2?JOURNALID=${JOURNALID}&JOURNALPRICINGID=${JOURNALPRICINGID}&MODIFIEDBY=${MODIFIEDBY}`
            )
            const json = await response.json();
            const data = JSON.parse(json)[0];
            if (data && data.ReturnVal === 1) {
                dispatch(slice.actions.updateJournalSuccess(JSON.parse(data.ReturnData)));
            } else {
                dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// View Journal Listing
export function Journal_ViewJournal(USERID, JOURNALID, JOURNALPAPERIND, JOURNALREVIEWERIND, JOURNALDASHBOARDIND) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            console.log("Journal_ViewJournal", `${url}Journal_ViewJournal?USERID=${USERID}&JOURNALID=${JOURNALID}&JOURNALPAPERIND=${JOURNALPAPERIND}&JOURNALREVIEWERIND=${JOURNALREVIEWERIND}&JOURNALDASHBOARDIND=${JOURNALDASHBOARDIND}`)
            const response = await fetch(
                `${url}Journal_ViewJournal?USERID=${USERID}&JOURNALID=${JOURNALID}&JOURNALPAPERIND=${JOURNALPAPERIND}&JOURNALREVIEWERIND=${JOURNALREVIEWERIND}&JOURNALDASHBOARDIND=${JOURNALDASHBOARDIND}`
            )
            const json = await response.json();
            const data = JSON.parse(json)[0];
            if (data && data.ReturnVal === 1) {
                dispatch(slice.actions.getJournalSuccess(JSON.parse(data.ReturnData)));
            } else {
                dispatch(slice.actions.hasError(JSON.parse(data.ReturnData).ReturnSqlError));
            }
        } catch (error) {
            console.log(error)
            dispatch(slice.actions.hasError(error));
        }
    };
}

// View Journal Status
export function General_ViewJournalStatus() {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await fetch(
                `${url}General_ViewJournalStatus`
            )
            const json = await response.json();
            const data = JSON.parse(json)[0];
            if (data && data.ReturnVal === 1) {
                dispatch(slice.actions.getJournalStatusSuccess(JSON.parse(data.ReturnData)));
            } else {
                dispatch(slice.actions.hasError(JSON.parse(data.ReturnData).ReturnSqlError));
            }
        } catch (error) {
            console.log(error)
            dispatch(slice.actions.hasError(error));
        }
    };
}

// Final Update Journal while create new
export function Journal_UpdateJournalConfirmation(JOURNALID, MODIFIEDBY) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());

        try {
            const response = await fetch(
                `${url}Journal_UpdateJournalConfirmation?JOURNALID=${JOURNALID}&MODIFIEDBY=${MODIFIEDBY}`
            )
            const json = await response.json();
            const data = JSON.parse(json)[0];
            if (data && data.ReturnVal === 1) {
                dispatch(slice.actions.updateJournalConfirmationSuccess(JSON.parse(data.ReturnData)));
            } else {
                dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function Journal_UpdateJournalStatus(JOURNALID, JOURNALSTATUSID, MODIFIEDBY) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());

        try {
            const response = await fetch(
                `${url}Journal_UpdateJournalStatus?JOURNALID=${JOURNALID}&JOURNALSTATUSID=${JOURNALSTATUSID}&MODIFIEDBY=${MODIFIEDBY}`
            )
            const json = await response.json();
            const data = JSON.parse(json)[0];
            if (data && data.ReturnVal === 1) {
                dispatch(slice.actions.updateJournalStatusSuccess(JSON.parse(data.ReturnData)));
            } else {
                dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// Delete Journal
export function Journal_DeleteJournal(JOURNALID, MODIFIEDBY) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());

        try {
            const response = await fetch(
                `${url}Journal_DeleteJournal?JOURNALID=${JOURNALID}&MODIFIEDBY=${MODIFIEDBY}`
            )
            const json = await response.json();
            const data = JSON.parse(json)[0];
            if (data && data.ReturnVal === 1) {
                dispatch(slice.actions.deleteJournalSuccess(JSON.parse(data.ReturnData)));
            } else {
                dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// Add Journal User Access
export function AddJournalUserAccess(JOURNALID, USEREMAIL, ROLEID, CREATEDBY) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());

        try {
            const response = await fetch(
                `${url}Journal_AddJournalUserAccess?JOURNALID=${JOURNALID}&USEREMAIL=${USEREMAIL}&ROLEID=${ROLEID}&CREATEDBY=${CREATEDBY}`
            )
            const json = await response.json();
            const data = JSON.parse(json)[0];
            if (data && data.ReturnVal === 1) {
                dispatch(slice.actions.addJournalUserAccessSuccess(JSON.parse(data.ReturnData)));
            } else {
                dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// View Journal User Access
export function getJournalUserAccess(JOURNALID) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());

        try {
            const response = await fetch(
                `${url}Journal_ViewJournalUserAccess?JOURNALID=${JOURNALID}`
            )
            const json = await response.json();
            const data = JSON.parse(json)[0];
            if (data && data.ReturnVal === 1) {
                dispatch(slice.actions.getJournalUserAccessSuccess(JSON.parse(data.ReturnData)));
            } else {
                dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// Delete Journal User Access
export function deleteJournalUserAccess(JOURNALUSERACCESSID, MODIFIEDBY) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());

        try {
            const response = await fetch(
                `${url}Journal_DeleteJournalUserAccess?JOURNALUSERACCESSID=${JOURNALUSERACCESSID}&MODIFIEDBY=${MODIFIEDBY}`
            )
            const json = await response.json();
            const data = JSON.parse(json)[0];
            if (data && data.ReturnVal === 1) {
                dispatch(slice.actions.deleteJournalUserAccessSuccess(JSON.parse(data.ReturnData)));
            } else {
                dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function clearJournalData() {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.clearJournalDataSuccess());

        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function unsubscribeJournal() {
    return async (dispatch) => {
        dispatch(slice.actions.unsubscribeJournal());
    };
}


