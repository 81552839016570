import { createSlice } from '@reduxjs/toolkit';
// utils
import { ServerConfiguration } from '../../utils/serverConfig';

// ----------------------------------------------------------------------

const url = ServerConfiguration.testingServerUrl;

// const projectId = 1;

const initialState = {
    isLoading: false,
    error: null,
    journalsbyUser: null,
    manuscriptReturn: null,
    updatejournal: null,
    deletejournal: null,
    deleteauthor: null,
    deletefile: null,
    status: [],
    endorsedJournal: [],
    submission: {
        activeStep: 0,
        papertype: '',
        special: null,
        presented: null,
        title: '',
        abstract: '',
        inspiration: '',
        //
        files: [],
        designations: [],
        //
        keywords: [],
        categories: [],
        //
        isAuthor: null,
        isCoAuthor: null,
        coAuthors: [],
        //
        coverLetter: '',
        isFunding: null,
        funding: [],
        isSubmittedPreviously: null,
        fundingComment: "",
        manuscriptId: '',
        noOfTables: 0,
        noOfFigures: 0,
        noOfWords: 0,
        paymentOption: null,
        figureDesignation: null,
        summary: '',
        declaration: [],
    },
    typeOfPaper: [
        { label: 'Guest Editorial', value: '1', desc: '' },
        { label: 'Corrigendum', value: '2', desc: '' },
        { label: 'Original Research', value: '3', desc: 'Report of original research findings and data should include information, Methods, Results' },
        { label: 'Industry Article', value: '4', desc: 'A report from industrial-based scientists undertaking R&D on a new technology' },
        { label: 'Case Study', value: '5', desc: 'Report on specific instance of an interesting or particular phenomena' },
        { label: 'Comment', value: '6', desc: 'Critique of already published paper.' },
    ],
    designations: [],
    step1Result: null,
    step2Result: null,
    step3Result: null,
    step4Result: null,
    step5Result: null,
    step6Result: null,
};

const slice = createSlice({
    name: 'journal',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // BACK STEP
        backStep(state) {
            state.submission.activeStep -= 1;
        },

        // NEXT STEP
        nextStep(state) {
            state.submission.activeStep += 1;
        },

        // RESET STEP
        resetStep(state) {
            state.submission.activeStep = 0;
        },

        // GO TO STEP
        goToStep(state, action) {
            state.submission.activeStep = action.payload;
        },

        // UPDATE PAPER INTRO
        updatePaperIntro(state, action) {
            state.submission.papertype = action.payload.papertype;
            state.submission.special = action.payload.special;
            state.submission.presented = action.payload.presented;
            state.submission.title = action.payload.title;
            state.submission.abstract = action.payload.abstract;
            state.submission.inspiration = action.payload.inspiration;
        },

        // UPDATE FILE UPLOAD
        updateFileUpload(state, action) {
            state.submission.files = action.payload.files;
            state.submission.designations = action.payload.designations;
        },

        // UPDATE ATTRIBUTES
        updateAttributes(state, action) {
            state.submission.keywords = action.payload.keywords;
            state.submission.categories = action.payload.categories;
        },

        // UPDATE AUTHOR & INSTITUTION
        updateAuthorInstitution(state, action) {
            state.submission.isAuthor = action.payload.isAuthor;
            state.submission.isCoAuthor = action.payload.isCoAuthor;
            state.submission.coAuthors = action.payload.coAuthors;
        },

        // UPDATE DETAILS & COMMENTS
        updateDetailComment(state, action) {
            state.submission.coverLetter = action.payload.coverLetter;
            state.submission.isFunding = action.payload.isFunding;
            state.submission.funding = action.payload.fundings;
            state.submission.isSubmittedPreviously = action.payload.isSubmittedPreviously;
            state.submission.fundingComment = action.payload.fundingComment;
            state.submission.manuscriptId = action.payload.manuscriptId;
            state.submission.noOfTables = action.payload.noOfTables;
            state.submission.noOfFigures = action.payload.noOfFigures;
            state.submission.noOfWords = action.payload.noOfWords;
            state.submission.paymentOption = action.payload.paymentOption;
            state.submission.figureDesignation = action.payload.figureDesignation;
            state.submission.summary = action.payload.summary;
            state.submission.declaration = action.payload.declaration;
        },

        // GET JOURNAL
        getJournalSuccess(state, action) {
            state.isLoading = false;
            state.journalsbyUser = action.payload;
        },

        // GET JOURNAL STATUS
        getJournalStatusSuccess(state, action) {
            state.isLoading = false;
            state.status = action.payload;
        },

        // ADD MANUSCRIPT
        addManuscriptSuccess(state, action) {
            state.isLoading = false;
            state.manuscriptReturn = action.payload;
        },

        // UPDATE JOURNAL
        updateJournalSuccess(state, action) {
            state.isLoading = false;
            state.updatejournal = action.payload;
        },

        // UNSUBSCRIBE UPDATE JOURNAL
        unsubscribeUpdateJournalSuccess(state, action) {
            state.isLoading = false;
            state.updatejournal = null;
        },

        // DELETE JOURNAL
        deleteJournalSuccess(state, action) {
            state.isLoading = false;
            state.deletejournal = action.payload;
        },

        // DELETE CO-AUTHOR FROM JOURNAL
        deleteCoAuthorFromJournalSuccess(state, action) {
            state.isLoading = false;
            state.deleteauthor = action.payload;
        },

        // DELETE FILE FROM SUBMISSION
        deleteFileSuccess(state, action) {
            state.isLoading = false;
            state.deletefile = action.payload;
        },

        // UNSUBSCRIBE ADD JOURNAL
        unsubscrbeAddManuscriptSuccess(state, action) {
            state.isLoading = false;
            state.manuscriptReturn = null;
        },

        unsubscribeDeleteJournalSuccess(state, action) {
            state.isLoading = false;
            state.deletejournal = null;
        },

        // GET DESIGNATIONS
        getDesignationsSuccess(state, action) {
            state.isLoading = false;
            state.designations = action.payload;
        },

        getEndorsedJournalSuccess(state, action) {
            state.isLoading = false;
            state.endorsedJournal = action.payload;
        },

        // RESET FILES
        resetFiles(state) {
            state.submission.files = [];
        },

        // RESET STATES
        resetState(state) {
            state.submission = { ...initialState.submission };
        },

        // STEP 1 RESULT
        getStep1ResultSuccess(state, action) {
            state.step1Result = action.payload;
        },

        // STEP 2 RESULT
        getStep2ResultSuccess(state, action) {
            state.step2Result = action.payload;
        },

        // STEP 3 RESULT
        getStep3ResultSuccess(state, action) {
            state.step3Result = action.payload;
        },

        // STEP 4 RESULT
        getStep4ResultSuccess(state, action) {
            state.step4Result = action.payload;
        },

        // STEP 5 RESULT
        getStep5ResultSuccess(state, action) {
            state.step5Result = action.payload;
        },

        // STEP 6 RESULT
        getStep6ResultSuccess(state, action) {
            state.step6Result = action.payload;
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    backStep,
    nextStep,
    resetStep,
    goToStep,
    updatePaperIntro,
    updateFileUpload,
    updateAttributes,
    updateAuthorInstitution,
    updateDetailComment,
    unsubscrbeAddManuscriptSuccess,
    unsubscribeUpdateJournalSuccess,
    unsubscribeDeleteJournalSuccess,
    resetFiles,
    resetState,
} = slice.actions;

// ----------------------------------------------------------------------

export function getJournal(userId, project, journalId) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await fetch(
                `${url}Paper_AuthorPaper_PaperListByUserID?USERID=${userId}&PROJECTID=${project}&JOURNALID=${journalId}`
            )
            const json = await response.json();
            const data = JSON.parse(json)[0];
            if (data && data.ReturnVal === 1) {
                dispatch(slice.actions.getJournalSuccess(JSON.parse(data.ReturnData)));
            } else {
                dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getConferencePaperList(userId, paperId, conferenceId) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await fetch(
                `${url}Paper_AuthorPaper_ConferencePaperListByUserID?USERID=${userId}&PAPERID=${paperId}&CONFERENCEID=${conferenceId}`
            )
            const json = await response.json();
            const data = JSON.parse(json)[0];
            console.log(data);
            if (data && data.ReturnVal === 1) {
                dispatch(slice.actions.getJournalSuccess(JSON.parse(data.ReturnData)));
            } else {
                dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getJournalStatus() {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await fetch(
                `${url}General_View_PaperStatus`
            )
            const json = await response.json();
            const data = JSON.parse(json)[0];
            console.log(data);
            if (data && data.ReturnVal === 1) {
                dispatch(slice.actions.getJournalStatusSuccess(JSON.parse(data.ReturnData)));
            } else {
                dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function addManuscript(userId, conferenceId, project, journalId) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await fetch(
                `${url}Paper_AuthorPaper_PaperAdd?USERID=${userId}&PROJECTID=${project}&CONFERENCEID=${conferenceId}`
            )
            const json = await response.json();
            const data = JSON.parse(json)[0];
            if (data && data.ReturnVal === 1) {
                dispatch(slice.actions.addManuscriptSuccess(JSON.parse(data.ReturnData)[0]));
                getJournal(userId, project, journalId)(dispatch);
            } else {
                dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function updateJournal(paperId, paperStatusId, parentPaperId, values, modifyBy, resubmissionInd, projectId) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await fetch(
                `${url}Paper_AuthorPaper_PaperUpdate?PAPERID=${paperId}&PAPERTYPE=${values.papertype}&PAPERCATEGORY=${values.categories}&PAPERKEYWORD=${values.keywords}&PAPERTITLE=${values.title}&PAPERABSTRACT=${values.abstract}&PAPERCOVERLETTER=${values.coverLetter}&PAPERPURPOSE=${values.inspiration}&PAPERSUMMARY=${values.summary}&PAPERSPECIALISSUE=${values.special}&ISFUNDING=${values.isFunding}&PAPERPAYMENT=${values.paymentOption}&PAPERFIGURENO=${values.noOfFigures}&PAPERTABLENO=${values.noOfTables}&PAPERWORDNO=${values.noOfWords}&ISRESUBMISSION=${resubmissionInd}&ISPAPERORIGINAL=${values.presented}&PAPERSTATUSID=${paperStatusId}&DRAFTPAPERSTAGE=${values.activeStep}&PARENTPAPERID=${parentPaperId}&PROJECTID=${projectId}&ISPAPERSUBMIT=${values.isPaperSubmit}&MODIFYBY=${modifyBy}&COAUTHOREMAIL=${values.coAuthors}&PAPERFILEDESIGNATIONID=${values.designations}&PAPERFILEURL=${values.fileName}&PAPERFILETYPE=${values.fileType}&ISSUBMITAGENT=${values.isAuthor}&ISCOAUTHOREXIST=${values.isCoAuthor}&FUNDINGSOURCE=${values.fundingComment}&FIGUREDESTINATION=${values.figureDesignation}&DECLARATIONID=${values.declaration}&FUNDERNAME=${values.funders}&GRANTREFERENCE=${values.refNo}`
            )
            const json = await response.json();
            const data = JSON.parse(json)[0];
            console.log(data);
            if (data && data.ReturnVal === 1) {
                dispatch(slice.actions.updateJournalSuccess(JSON.parse(data.ReturnData)[0]));
            } else {
                dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function unsubscribeUpdateJournal() {
    return async (dispatch) => {
        dispatch(slice.actions.unsubscribeUpdateJournalSuccess());
    };
}

export function unsubscribeAddJournal() {
    return async (dispatch) => {
        dispatch(slice.actions.unsubscrbeAddManuscriptSuccess());
    };
}

export function unsubscribeUpdateProfile() {
    return async (dispatch) => {
      dispatch(slice.actions.unsubscribeDeleteJournalSuccess());
    };
  }
  
// ----------------------------------------------------------------------

export function deleteJournal(paperID, modifyBy) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await fetch(
                `${url}Paper_AuthorPaper_PaperDelete?PAPERID=${paperID}&MODIFYBY=${modifyBy}`
            )
            const json = await response.json();
            const data = JSON.parse(json)[0];
            console.log(data);
            if (data && data.ReturnVal === 1) {
                dispatch(slice.actions.deleteJournalSuccess(JSON.parse(data.ReturnData)));
            } else {
                dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function deleteCoAuthorFromJournal(paperAuthorID, modifyBy) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await fetch(
                `${url}Paper_AuthorPaper_AuthorListDelete?PAPERAUTHORID=${paperAuthorID}&MODIFYBY=${modifyBy}`
            )
            const json = await response.json();
            const data = JSON.parse(json)[0];
            console.log(data);
            if (data && data.ReturnVal === 1) {
                dispatch(slice.actions.deleteCoAuthorFromJournalSuccess(JSON.parse(data.ReturnData)));
            } else {
                dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function deleteFile(paperFileID, modifyBy) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await fetch(
                `${url}Paper_AuthorPaper_FileDelete?PAPERFILEID=${paperFileID}&MODIFYBY=${modifyBy}`
            )
            const json = await response.json();
            const data = JSON.parse(json)[0];
            console.log(data);
            if (data && data.ReturnVal === 1) {
                dispatch(slice.actions.deleteFileSuccess(JSON.parse(data.ReturnData)));
            } else {
                dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------
//  change journalsbyUser in reducer locally

//  only used when the data in database is updated

export function setJournalListing(ori_listing) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            if (ori_listing && ori_listing.length >= 1) {
                dispatch(slice.actions.getJournalSuccess(ori_listing));
            } else {
                dispatch(slice.actions.hasError("Delete file error"));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getDesignations() {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await fetch(
                `${url}General_ViewFileDesignation`
            )
            const json = await response.json();
            const data = JSON.parse(json)[0];
            if (data && data.ReturnVal === 1) {
                dispatch(slice.actions.getDesignationsSuccess(JSON.parse(data.ReturnData)));
            } else {
                dispatch(slice.actions.hasError(JSON.parse(data.ReturnData).ReturnSqlError));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getEndorsedJournal() {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await fetch(
                `${url}Journal_ViewEndorsedJournal`
            )
            const json = await response.json();
            const data = JSON.parse(json)[0];
            if (data && data.ReturnVal === 1) {
                dispatch(slice.actions.getEndorsedJournalSuccess(JSON.parse(data.ReturnData)));
            } else {
                dispatch(slice.actions.hasError(JSON.parse(data.ReturnData).ReturnSqlError));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getStep1Result(values, projectId, modifyBy) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await fetch(
                `${url}Paper_AuthorPaper_PaperUpdate_Stage1?PAPERID=${values.paperId}&PAPERTYPE=${values.papertype}&JOURNALID=${values.journal}&PAPERSPECIALISSUE=${values.special}&ISPAPERORIGINAL=${values.presented}&PAPERTITLE=${values.title}&PAPERABSTRACT=${values.abstract}&PAPERPURPOSE=${values.inspiration}&PARENTPAPERID=${values.parentPaperId}&CONFERENCEPAPERID=${values.conferencePaperId}&PROJECTID=${projectId}&MODIFYBY=${modifyBy}`
            )
            console.log("gdtiojsgiW", `${url}Paper_AuthorPaper_PaperUpdate_Stage1?PAPERID=${values.paperId}&PAPERTYPE=${values.papertype}&JOURNALID=${values.journal}&PAPERSPECIALISSUE=${values.special}&ISPAPERORIGINAL=${values.presented}&PAPERTITLE=${values.title}&PAPERABSTRACT=${values.abstract}&PAPERPURPOSE=${values.inspiration}&PARENTPAPERID=${values.parentPaperId}&CONFERENCEPAPERID=${values.conferencePaperId}&PROJECTID=${projectId}&MODIFYBY=${modifyBy}`)
            const json = await response.json();
            const data = JSON.parse(json)[0];
            console.log(JSON.parse(data.ReturnData)[0]);
            if (data && data.ReturnVal === 1) {
                dispatch(slice.actions.getStep1ResultSuccess(JSON.parse(data.ReturnData)[0]));
                return true;
            }
            dispatch(slice.actions.hasError(JSON.parse(data.ReturnData).ReturnSqlError));
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function getStep2Result(values, currentJournal, projectId, modifyBy) {
    // author submission url : `${url}Paper_AuthorPaper_PaperUpdate_Stage2?PAPERID=${currentJournal.PaperID}&PAPERFILEDESIGNATIONID=${values.designations}&PAPERFILEURL=${values.files}&PROJECTID=${projectId}&MODIFYBY=${modifyBy}`


    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await fetch(
                `${url}Paper_AuthorPaper_PaperUpdate_Stage2?PAPERID=${values.PaperID}&PAPERFILEDESIGNATIONID=${values.designations}&PAPERFILEURL=${values.files}&PROJECTID=${projectId}&MODIFYBY=${modifyBy}`
            )
            const json = await response.json();
            const data = JSON.parse(json)[0];
            if (data && data.ReturnVal === 1) {
                dispatch(slice.actions.getStep2ResultSuccess(JSON.parse(data.ReturnData)[0]));
                return true;
            }
            dispatch(slice.actions.hasError(JSON.parse(data.ReturnData).ReturnSqlError));
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function getStep3Result(values, projectId, modifyBy) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await fetch(
                `${url}Paper_AuthorPaper_PaperUpdate_Stage3?PAPERID=${values.paperId}&PAPERCATEGORY=${values.categories}&PAPERKEYWORD=${values.keywords}&PROJECTID=${projectId}&MODIFYBY=${modifyBy}`
            )
            console.log("hhgjghjgjh", `${url}Paper_AuthorPaper_PaperUpdate_Stage3?PAPERID=${values.paperId}&PAPERCATEGORY=${values.categories}&PAPERKEYWORD=${values.keywords}&PROJECTID=${projectId}&MODIFYBY=${modifyBy}`)
            const json = await response.json();
            const data = JSON.parse(json)[0];
            if (data && data.ReturnVal === 1) {
                dispatch(slice.actions.getStep3ResultSuccess(JSON.parse(data.ReturnData)[0]));
                return true;
            }
            dispatch(slice.actions.hasError(JSON.parse(data.ReturnData).ReturnSqlError));
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            console.log("fsfs", error)
            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function getStep4Result(values, projectId, modifyBy) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await fetch(
                `${url}Paper_AuthorPaper_PaperUpdate_Stage4?PAPERID=${values.paperId}&ISSUBMITAGENT=${values.isSubmitAgent}&ISCOAUTHOREXIST=${values.isCoAuthor}&COAUTHOREMAIL=${values.coAuthorEmail}&COAUTHORNAME=${values.coAuthorName}&COAUTHORINSTITUTE=${values.coAuthorInstitution}&COPREFIX=${values.COPREFIX}&PROJECTID=${projectId}&MODIFYBY=${modifyBy}`
            )
            console.log("kjdlasd", `${url}Paper_AuthorPaper_PaperUpdate_Stage4?PAPERID=${values.paperId}&ISSUBMITAGENT=${values.isSubmitAgent}&ISCOAUTHOREXIST=${values.isCoAuthor}&COAUTHOREMAIL=${values.coAuthorEmail}&COAUTHORNAME=${values.coAuthorName}&COAUTHORINSTITUTE=${values.coAuthorInstitution}&COPREFIX=${values.COPREFIX}&PROJECTID=${projectId}&MODIFYBY=${modifyBy}`)
            const json = await response.json();
            const data = JSON.parse(json)[0];
            if (data && data.ReturnVal === 1) {
                dispatch(slice.actions.getStep4ResultSuccess(JSON.parse(data.ReturnData)[0]));
                return true;
            }
            dispatch(slice.actions.hasError(JSON.parse(data.ReturnData).ReturnSqlError));
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function getStep5Result(values, projectId, modifyBy) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await fetch(
                `${url}Paper_AuthorPaper_PaperUpdate_Stage5?PAPERID=${values.paperId}&PAPERCOVERLETTER=${values.coverLetter}&ISFUNDING=${values.isFunding}&PAPERPAYMENT=${values.paymentOption}&PAPERFIGURENO=${values.noOfFigures}&PAPERTABLENO=${values.noOfTables}&PAPERWORDNO=${values.noOfWords}&FUNDINGSOURCE=${values.fundingComment}&FIGUREDESTINATION=${values.figureDesignation}&PAPERSUMMARY=${values.summary}&DECLARATIONID=${values.declaration}&FUNDERNAME=${values.funders}&GRANTREFERENCE=${values.refNo}&PROJECTID=${projectId}&MODIFYBY=${modifyBy}`
            )
            const json = await response.json();
            const data = JSON.parse(json)[0];
            if (data && data.ReturnVal === 1) {
                dispatch(slice.actions.getStep5ResultSuccess(JSON.parse(data.ReturnData)[0]));
                return true;
            }
            dispatch(slice.actions.hasError(JSON.parse(data.ReturnData).ReturnSqlError));
            return false;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}

// ----------------------------------------------------------------------

export function getStep6Result(values, projectId, modifyBy) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await fetch(
                `${url}Paper_AuthorPaper_PaperUpdate_Stage6?PAPERID=${values.paperId}&ISPAPERSUBMIT=${values.isPaperSubmit}&PROJECTID=${projectId}&MODIFYBY=${modifyBy}`
            )
            const json = await response.json();
            const data = JSON.parse(json)[0];
            if (data && data.ReturnVal === 1) {
                dispatch(slice.actions.getStep6ResultSuccess(JSON.parse(data.ReturnData)[0]));
            } else {
                dispatch(slice.actions.hasError(JSON.parse(data.ReturnData).ReturnSqlError));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function userAcceptAction({ id, userId, statusId, modifyBy }) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await fetch(
                `${url}Journal_UpdateJournalUserAccessStatus?JOURNALID=${id}&USERID=${userId}&USERSTATUSID=${statusId}&MODIFIEDBY=${modifyBy}`
            )
            const json = await response.json();
            const data = JSON.parse(json)[0];
            if (data) {
                dispatch(slice.actions.userAcceptActionSuccess(JSON.parse(data.ReturnData)[0]));
            } else {
                dispatch(slice.actions.hasError(JSON.parse(data.ReturnData).ReturnSqlError));
            }
        } catch (error) {
            console.log(error)
            dispatch(slice.actions.hasError(error));
        }
    };
}