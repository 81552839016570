import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { ServerConfiguration } from '../../utils/serverConfig';

// ----------------------------------------------------------------------

const url = ServerConfiguration.testingServerUrl;
const PROJECTID = 1;
const initialState = {
  isLoading: false,
  error: null,
  tasks: [],
  taskStatus: [],
  inviteEditorAction:[],
  createTask: null,
  deleteTask: null,
  response: null,
  returnReview: null,
  updateReview: null,
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET TASKS
    getTasksSuccess(state, action) {
      state.isLoading = false;
      state.tasks = action.payload;
    },

    // GET TASK STATUS
    getTaskStatusSuccess(state, action) {
      state.isLoading = false;
      state.taskStatus = action.payload;
    },

    // CREATE TASK
    createTaskSuccess(state, action) {
      state.isLoading = false;
      state.createTask = action.payload;
    },

    // DELETE TASK
    deleteTaskSuccess(state, action) {
      state.isLoading = false;
      state.deleteTask = action.payload;
    },

    // RESPONSE
    responseSuccess(state, action) {
      state.isLoading = false;
      state.response = action.payload;
    },

    // RETURN REVIEW
    returnReviewSuccess(state, action) {
      state.isLoading = false;
      state.returnReview = action.payload;
    },

    // UPDATE REVIEW
    updateReviewSuccess(state, action) {
      state.isLoading = false;
      state.updateReview = action.payload;
    },


  },
});

// Reducer
export default slice.reducer;

// Actions
export const { onOpenModal, onCloseModal, selectUser, selectRange } = slice.actions;

export function getTasks(userId, projectId,journalId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Paper_AuthorPaper_PaperListByUserID?USERID=${userId}&PROJECTID=${projectId}&JOURNALID=${journalId}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getTasksSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getTaskStatus() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(`${url}Paper_Task_ViewTaskStatus`);
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getTaskStatusSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createTask(
  email,
  projectId,
  paperId,
  modifiedBy,
  status,
  role,
  type,
  subject,
  body,
  dueDate,
  journalId
) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
   
    try {
      const response = await fetch(
        `${url}Paper_Task_RequestAndAssignment?USEREMAIL=${email}&PROJECTID=${projectId}&PAPERID=${paperId}&MODIFYBY=${modifiedBy}&TASKSTATUS=${status}&TASKUSERROLE=${role}&TYPE=${type}&SUBJECT=${subject}&BODY=${body}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
   
      if (data && data.ReturnVal === 1) {
        const response2 = await fetch(`${url}Paper_AuthorPaper_PaperListByUserID?USERID=${modifiedBy}&PROJECTID=${projectId}&JOURNALID=${journalId}`);
        const json2 = await response2.json();
        const data2 = JSON.parse(json2)[0];
        if (data2 && data2.ReturnVal === 1) {
          dispatch(slice.actions.getTasksSuccess(JSON.parse(data2.ReturnData)));
        } else {
          dispatch(slice.actions.hasError(JSON.parse(data2.ReturnData)[0].ReturnSqlError));
        }
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteTask(taskId, modifiedBy) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Paper_Task_AssignEditorDelete?USERTASKID=${taskId}&MODIFYBY=${modifiedBy}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.deleteTaskSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function reviewerResponse(taskId, email, acceptInd, status, date, projectId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Paper_Task_InviteReviewerRespond?USERTASKID=${taskId}&USEREMAIL=${email}&ISTASKACCEPTED=${acceptInd}&TASKSTATUS=${status}&TASKEXTENDDATE=${date}&PROJECTID=${projectId}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.responseSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function returnReview(
  emailType,
  subject,
  body,
  modifiedBy,
  paperId,
  taskId,
  draftInd,
  status,
  emailId,
  projectId,
  acceptInd
) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Paper_Task_ReviewerReturn?EMAILTYPE=${emailType}&SUBJECT=${subject}&BODY=${body}&MODIFYBY=${modifiedBy}&PAPERID=${paperId}&TASKID=${taskId}&ISDRAFT=${draftInd}&TASKSTATUS=${status}&EMAILID=${emailId}&PROJECTID=${projectId}&ISPAPERACCEPTED=${acceptInd}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.returnReviewSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateReview(
  reviewId,
  modifiedBy,
  paperId,
  title,
  description,
  projectId,
  draftInd,
  taskId,
  acceptInd,
  status,
  subject,
  body
) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Paper_Task_ReviewerUpdate?REVIEWID=${reviewId}&MODIFYBY=${modifiedBy}&PAPERID=${paperId}&REVIEWTITLE=${title}&REVIEWDESCRIPTION=${description}&PROJECTID=${projectId}&ISDRAFT=${draftInd}&TASKID=${taskId}&ISPAPERACCEPT=${acceptInd}&TASKSTATUS=${status}&SUBJECT=${subject}&BODY=${body}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updateReviewSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


