import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import {
  Radio,
  FormLabel,
  RadioGroup,
  FormControl,
  FormHelperText,
  FormControlLabel,
  Typography,
  Stack,
  Grid
} from '@mui/material';

// ----------------------------------------------------------------------

RHFRadioGroup.propTypes = {
  row: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array,
  spacing: PropTypes.number,
  helperText: PropTypes.node,
  horizontal: PropTypes.bool,
};

export default function RHFRadioGroup({
  row,
  name,
  label,
  options,
  spacing,
  horizontal,
  helperText,
  ...other
}) {
  const { control } = useFormContext();

  const labelledby = label ? `${name}-${label}` : '';

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl component="fieldset">
          {label && (
            <FormLabel component="legend" id={labelledby} sx={{ typography: 'body2' }}>
              {label}
            </FormLabel>
          )}

          {
            horizontal ?
              <RadioGroup {...field} aria-labelledby={labelledby} row={row} {...other}>
                {options.map((option, index) => (
                  <Grid key={`h_${index}`} container alignItems='center'>
                    <Grid item xs={12} md={5}>
                      <FormControlLabel
                        key={option.value}
                        value={option.value}
                        control={<Radio />}
                        label={option.label}
                        sx={{
                          '&:not(:last-of-type)': {
                            mb: spacing || 0,
                          },
                          ...(row && {
                            mr: 0,
                            '&:not(:last-of-type)': {
                              mr: spacing || 2,
                            },
                          }),
                        }}
                      />
                    </Grid>
                    {option.desc && <Grid item xs={12} md={7}>{option.desc && <Typography style={{ fontSize: "0.85vw" }}>{option.desc}</Typography>}</Grid>}
                  </Grid>
                ))}
              </RadioGroup>
              :
              <RadioGroup {...field} aria-labelledby={labelledby} row={row} {...other}>
                {options.map((option, index) => (
                  <Stack key={`v_${index}`} direction="column">
                    <FormControlLabel
                      key={option.value}
                      value={option.value}
                      control={<Radio />}
                      label={option.label}
                      sx={{
                        '&:not(:last-of-type)': {
                          mb: spacing || 0,
                        },
                        ...(row && {
                          mr: 0,
                          '&:not(:last-of-type)': {
                            mr: spacing || 2,
                          },
                        }),
                      }}
                    />
                    {option.desc && <Typography variant='caption' sx={{ color: 'text.secondary' }} ml={3.7}> {option.desc}</Typography>}
                  </Stack>
                ))}
              </RadioGroup>
          }

          {(!!error || helperText) && (
            <FormHelperText error={!!error} sx={{ mx: 0 }}>
              {error ? error?.message : helperText}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}
