import { Suspense, lazy } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
(
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// ----------------------------------------------------------------------

// AUTH
export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')));
export const RegisterPage = Loadable(lazy(() => import('../pages/auth/RegisterPage')));
export const VerifyCodePage = Loadable(lazy(() => import('../pages/auth/VerifyCodePage')));
export const NewPasswordPage = Loadable(lazy(() => import('../pages/auth/NewPasswordPage')));
export const ResetPasswordPage = Loadable(lazy(() => import('../pages/auth/ResetPasswordPage')));

// LANDING
// export const MarketingAboutPage = Loadable(
//   lazy(() => import('../pages/landing/MarketingAboutPage'))
// );
// export const MarketingBlogPage = Loadable(
//   lazy(() => import('../pages/landing/MarketingBlogPage'))
// );
// export const MarketingCaseStudiesPage = Loadable(
//   lazy(() => import('../pages/landing/MarketingCaseStudiesPage'))
// );
// export const MarketingCaseStudyPage = Loadable(
//   lazy(() => import('../pages/landing/MarketingCaseStudyPage'))
// );
// export const MarketingContactPage = Loadable(
//   lazy(() => import('../pages/landing/MarketingContactPage'))
// );
export const MarketingLandingPage = Loadable(
  lazy(() => import('../pages/landing/MarketingLandingPage'))
);
// export const MarketingPostPage = Loadable(
//   lazy(() => import('../pages/landing/MarketingPostPage'))
// );
// export const MarketingServicesPage = Loadable(
//   lazy(() => import('../pages/landing/MarketingServicesPage'))
// );

// DASHBOARD: GENERAL
// export const GeneralAppPage = Loadable(lazy(() => import('../pages/dashboard/generalPage/GeneralAppPage')));
// export const GeneralEcommercePage = Loadable(
//   lazy(() => import('../pages/dashboard/generalPage/GeneralEcommercePage'))
// );
// export const GeneralAnalyticsPage = Loadable(
//   lazy(() => import('../pages/dashboard/generalPage/GeneralAnalyticsPage'))
// );
// export const GeneralBankingPage = Loadable(
//   lazy(() => import('../pages/dashboard/generalPage/GeneralBankingPage'))
// );
// export const GeneralBookingPage = Loadable(
//   lazy(() => import('../pages/dashboard/generalPage/GeneralBookingPage'))
// );
// export const GeneralFilePage = Loadable(lazy(() => import('../pages/dashboard/generalPage/GeneralFilePage')));

// DASHBOARD: ECOMMERCE
// export const EcommerceShopPage = Loadable(
//   lazy(() => import('../pages/dashboard/EcommerceShopPage'))
// );
export const EcommerceProductDetailsPage = Loadable(
  lazy(() => import('../pages/dashboard/EcommerceProductDetailsPage'))
);
// export const EcommerceProductListPage = Loadable(
//   lazy(() => import('../pages/dashboard/EcommerceProductListPage'))
// );
// export const EcommerceProductCreatePage = Loadable(
//   lazy(() => import('../pages/dashboard/EcommerceProductCreatePage'))
// );
// export const EcommerceProductEditPage = Loadable(
//   lazy(() => import('../pages/dashboard/EcommerceProductEditPage'))
// );
export const EcommerceCheckoutPage = Loadable(
  lazy(() => import('../pages/dashboard/EcommerceCheckoutPage'))
);

// DASHBOARD: INVOICE
export const InvoiceListPage = Loadable(lazy(() => import('../pages/dashboard/invoice/InvoiceListPage')));
export const InvoiceDetailsPage = Loadable(
  lazy(() => import('../pages/dashboard/invoice/InvoiceDetailsPage'))
);
export const InvoiceCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/invoice/InvoiceCreatePage'))
);
export const InvoiceEditPage = Loadable(lazy(() => import('../pages/dashboard/invoice/InvoiceEditPage')));

// DASHBOARD: USER
export const UserProfilePage = Loadable(lazy(() => import('../pages/dashboard/userProfile/UserProfilePage')));
export const UserCardsPage = Loadable(lazy(() => import('../pages/dashboard/userProfile/UserCardsPage')));
export const UserListPage = Loadable(lazy(() => import('../pages/dashboard/userProfile/UserListPage')));
export const UserAccountPage = Loadable(lazy(() => import('../pages/dashboard/userProfile/UserAccountPage')));
export const UserCreatePage = Loadable(lazy(() => import('../pages/dashboard/userProfile/UserCreatePage')));
export const UserEditPage = Loadable(lazy(() => import('../pages/dashboard/userProfile/UserEditPage')));
export const UserCreatePublisherPage = Loadable(lazy(() => import('../pages/dashboard/CreateJournalPage')));
export const UserEditPublisherPage = Loadable(lazy(() => import('../pages/dashboard/publisherListing/EditPublisherPage')));
// export const UserCreateJournalPage = Loadable(lazy(() => import('../pages/dashboard/CreateJournalPage')));

// DASHBOARD: BLOG
// export const BlogPostsPage = Loadable(lazy(() => import('../pages/dashboard/BlogPostsPage')));
// export const BlogPostPage = Loadable(lazy(() => import('../pages/dashboard/BlogPostPage')));
// export const BlogNewPostPage = Loadable(lazy(() => import('../pages/dashboard/BlogNewPostPage')));

// DASHBOARD: FILE MANAGER
// export const FileManagerPage = Loadable(lazy(() => import('../pages/dashboard/FileManagerPage')));

// DASHBOARD: APP
// export const ChatPage = Loadable(lazy(() => import('../pages/dashboard/ChatPage')));
// export const MailPage = Loadable(lazy(() => import('../pages/dashboard/MailPage')));
// export const CalendarPage = Loadable(lazy(() => import('../pages/dashboard/CalendarPage')));
// export const KanbanPage = Loadable(lazy(() => import('../pages/dashboard/KanbanPage')));

// DASHBOARD: LEAVE
// export const LeaveApplicationListPage = Loadable(lazy(() => import('../pages/dashboard/LeaveApplicationListPage')));
// export const LeaveCreatePage = Loadable(lazy(() => import('../pages/dashboard/LeaveCreatePage')));
// export const LeaveDetailsPage = Loadable(lazy(() => import('../pages/dashboard/LeaveDetailsPage')));
// export const LeaveEditPage = Loadable(lazy(() => import('../pages/dashboard/LeaveEditPage')));

// AUTHOR SUBMISSION
export const AuthorDashboard = Loadable(lazy(() => import('../pages/dashboard/authorSubmission/GeneralAnalyticsPage')));
export const AuthorJournalList = Loadable(lazy(() => import('../pages/dashboard/authorSubmission/AuthorJournalList')));
export const AuthorManuscriptList = Loadable(lazy(() => import('../pages/dashboard/authorSubmission/AuthorManuscriptList')));
export const AuthorCreateManuscript = Loadable(lazy(() => import('../pages/dashboard/authorSubmission/AuthorCreateManuscript')));
export const JournalDetailsPage = Loadable(lazy(() => import('../pages/dashboard/authorSubmission/JournalDetailsPage')));


// Chief Editor
export const ChiefEditorDashboard = Loadable(lazy(() => import('../pages/dashboard/chiefEditor/GeneralAnalyticsPage')));
export const ChiefEditorViewTaskAssign = Loadable(lazy(() => import('../pages/dashboard/chiefEditor/taskAssignment/ChiefEditorTaskAssignment')));
export const EmailTemplatelisting = Loadable(lazy(() => import('../pages/dashboard/chiefEditor/setting/emailTemplate/emailTemplatelisting')));
export const UserManagement = Loadable(lazy(() => import('../pages/dashboard/chiefEditor/UserManagement/UserListing')));
export const UserDetails = Loadable(lazy(() => import('../pages/dashboard/chiefEditor/UserManagement/UserDetails')));


// REVIEWER
export const ReviewerDashboard = Loadable(lazy(() => import('../pages/dashboard/reviewer/GeneralAppPage')));
export const ReviewerManuscriptList = Loadable(lazy(() => import('../pages/dashboard/reviewer/ReviewerManuscriptList')));
export const ManuscriptDetailsPage = Loadable(lazy(() => import('../pages/dashboard/reviewer/ManuscriptDetailsPage')));
export const ReviewerInvitation = Loadable(lazy(() => import('../pages/dashboard/reviewer/ReviewerInvitation')));
// EDITOR
export const EditorDashboard = Loadable(lazy(() => import('../pages/dashboard/editor/EditorDashboard')));
export const ManuscriptList = Loadable(lazy(() => import('../pages/dashboard/editor/Manuscript/ManuscriptListing')));
export const ManuscriptDetail = Loadable(lazy(() => import('../pages/dashboard/editor/Manuscript/ManuscriptDetail')));
export const EditorInvitation = Loadable(lazy(() => import('../pages/dashboard/editor/EditorInvitation')));

// Conference Management
export const ConferenceEventManagementIndexPage = Loadable(lazy(() => import('../pages/dashboard/event-management/ConferenceEventManagementIndexPage')));
export const ConferenceEventManagementCardViewIndexPage = Loadable(lazy(() => import('../pages/dashboard/event-management/ConferenceEventManagementCardViewIndexPage')));
export const HistoryConferenceEventManagementCardViewIndexPage = Loadable(lazy(() => import('../pages/dashboard/event-management/HistoryConferenceEventManagementCardViewIndexPage')));
export const ConferenceEventManagementForm = Loadable(lazy(() => import('../pages/dashboard/event-management/ConferenceEventManagementForm')));
export const SurveyManagementIndexPage = Loadable(lazy(() => import('../pages/dashboard/survey-management/SurveyMamagementIndexPage')));
export const SurveyForm = Loadable(lazy(() => import('../pages/dashboard/survey-management/SurveyForm')));
export const JournalManagementIndexPage = Loadable(lazy(() => import('../pages/dashboard/journal-management/JournalManagementIndexPage')));
export const SettingManagement = Loadable(lazy(() => import('../pages/dashboard/settings/SettingManagement')));

export const ConferenceShopPage = Loadable(lazy(() => import('../pages/dashboard/event-management/shop/ConferenceShopPage')));
export const ConferenceShopDetailsPage = Loadable(lazy(() => import('../pages/dashboard/event-management/shop/ConferenceShopDetailsPage')));
// SURVEY FORM
export const SurveyFormPage = Loadable(lazy(() => import('../pages/SurveyFormPage')));

export const ConferencePaperSubmission = Loadable(lazy(() => import('../pages/dashboard/event-management/paper/ConferenceCreatePaper')));
export const ConferencePaperList = Loadable(lazy(() => import('../pages/dashboard/event-management/paper/ConferencePaperList')));
export const ConferencePaperDetailsPage = Loadable(lazy(() => import('../pages/dashboard/event-management/paper/ConferencePaperDetailsPage')));

// TRANSACTION
export const TransactionListPage = Loadable(lazy(() => import('../pages/dashboard/transaction/TransactionListPage')));
export const TransactionDetailsPage = Loadable(
  lazy(() => import('../pages/dashboard/transaction/TransactionDetailsPage'))
);

// JOURNAL MANAGEMENT (PUBLISHER)
export const JournalsCardDetail = Loadable(lazy(() => import('../pages/dashboard/publisherListing/JournalsCardDetail')));
export const JournalManagementCardViewIndexPage = Loadable(lazy(() => import('../pages/dashboard/journal-management/JournalManagementCardViewIndexPage')));

// ORGANIZATION TRANSACTION
export const OrganizerTransactionDetailsPage = Loadable(
  lazy(() => import('../pages/dashboard/transaction/OrganizerTransactionDetailsPage'))
);

// ADMIN CONFERENCE
export const AdminConferenceListPage = Loadable(lazy(() => import('../pages/dashboard/adminConference/AdminConferenceListPage')));
export const AdminConferenceDetailsPage = Loadable(lazy(() => import('../pages/dashboard/adminConference/AdminConferenceDetailsPage')));
export const AdminConferenceEditPage = Loadable(lazy(() => import('../pages/dashboard/adminConference/AdminConferenceEditPage')));

// ADMIN JOURNAL
export const AdminJournalListPage = Loadable(lazy(() => import('../pages/dashboard/adminJournal/AdminJournalListPage')));
export const AdminJournalDetailsPage = Loadable(lazy(() => import('../pages/dashboard/adminJournal/AdminJournalDetailsPage')));
export const AdminJournalEditPage = Loadable(lazy(() => import('../pages/dashboard/adminJournal/AdminJournalEditPage')));

// CONFERENCE ASSISTANT INVIATION
export const ConferenceAssistantInvitation = Loadable(lazy(() => import('../pages/dashboard/conferenceListing/ConferenceAssistantInvitation')));
export const JournalAssistantInvitation = Loadable(lazy(() => import('../pages/dashboard/publisherListing/JournalAssistantInvitation')));
// TEST RENDER PAGE BY ROLE
// export const PermissionDeniedPage = Loadable(
//   lazy(() => import('../pages/dashboard/PermissionDeniedPage'))
// );



