import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useCallback, useMemo } from 'react';
// utils
import axios from '../utils/axios';
import localStorageAvailable from '../utils/localStorageAvailable';
//
import { isValidToken, setSession, setUser, setRole } from './utils';
//
import { ServerConfiguration } from '../utils/serverConfig';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
  page: [],
  requestOtp: false,
  requestPWOtp: false,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === 'UPDATE_PROFILE') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === 'FORGOTPASSWORD') {
    return {
      ...state,
      requestPWOtp: true
    };
  }
  if (action.type === 'REQUEST_OTP') {
    return {
      ...state,
      requestOtp: true,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }

  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext(null);

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export function AuthProvider({ children }) {
  // Change this to your server url
  const url = ServerConfiguration.testingServerUrl;

  const [state, dispatch] = useReducer(reducer, initialState);

  const storageAvailable = localStorageAvailable();

  const initialize = useCallback(async () => {
    try {
      const accessToken = storageAvailable ? localStorage.getItem('user') : '';
      const accessRole = storageAvailable ? localStorage.getItem('role') : '';

      if (accessToken) {
        dispatch({
          type: 'INITIAL',
          payload: {
            isAuthenticated: true,
            user: JSON.parse(accessToken)[0],
          },
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, [storageAvailable]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email, projectId, password) => {
    const response = await fetch(
      `${url}User_Login?USEREMAIL=${email}&PROJECTID=${projectId}&PASSWORD=${password}`
    )
    const json = await response.json();
    const data = JSON.parse(json)[0];
    if (data && data.ReturnVal === 1) {
      setUser(data.ReturnData);
      dispatch({
        type: 'LOGIN',
        payload: {
          user: JSON.parse(data.ReturnData)[0],
        },
      });
      return true;
    }
    return false;
  }, [url]);

  // GOOGLE LOGIN
  const googleLogin = useCallback(async (email, projectId, token, type, firstName, lastName) => {
    const response = await fetch(
      `${url}User_Login_GoogleFB?USEREMAIL=${email}&PROJECTID=${projectId}&TOKEN=${token}&TYPE=${type}&FIRSTNAME=${firstName}&LASTNAME=${lastName}`
    )
    const json = await response.json();
    const data = JSON.parse(json)[0];
    if (data && data.StaffID !== 0) {
      dispatch({
        type: 'LOGIN',
        payload: {
          user: data,
        },
      });
      return true;
    }
    return false;
  }, [url]);

  // REGISTER
  const register = useCallback(async (user, projectId, userTypeId) => {
    const response = await fetch(
      `${url}User_Register_RequestOTP?USEREMAIL=${user.email}&PROJECTID=${projectId}&PASSWORD=${user.password}&USERCONTACTNO=${user.contactNo}&USERDOB=${user.dob}&USERORCHIDID=${user.orchidId}&USERPREFIX=${user.prefix}&USERFIRSTNAME=${user.firstName}&USERLASTNAME=${user.lastName}&USERINSTITUTION=${user.institution}&USERDEGREE=${user.degree}&USERADDRESS=${user.address}&USERSTATE=${user.nationState}&USERCOUNTRYID=${user.country}&USERCITY=${user.city}&USERPOSTALCODE=${user.zipCode}&USERRESEARCHID=${user.researchId}&USERGENDER=${user.gender}&REGISTERAUTHCODE=${user.REGISTERAUTHCODE}&USEREXPERTISE=${user.expertise}`
    )
    const json = await response.json();
    const data = JSON.parse(json)[0];
    if (data && data.ReturnVal === 1) {
      dispatch({
        type: 'REQUEST_OTP',
      });
      return JSON.parse(data.ReturnData)[0];
    }
    return false;
  }, [url]);  
  
  // FORGOT PASSWORD
  const forgotPassword = useCallback(async (USERID, TYPE, email, PROJECTID) => {
    console.log(`${url}User_Profile_SentOTPVerification?USERID=${USERID}&TYPE=${TYPE}&VALIDATIONFIELD=${email}&PROJECTID=${PROJECTID}`)
    const response = await fetch(
      `${url}User_Profile_SentOTPVerification?USERID=${USERID}&TYPE=${TYPE}&VALIDATIONFIELD=${email}&PROJECTID=${PROJECTID}`
    )
    const json = await response.json();
    const data = JSON.parse(json)[0];
    if (data && data.ReturnVal === 1) {
      dispatch({
        type: 'FORGOTPASSWORD',
      });
      return JSON.parse(data.ReturnData)[0];
    }
    return false;
  }, [url]);

    // VERIFY FORGOT PASSWORD
    const verifyForgotPasswordOTP = useCallback(async (USEREMAIL, OTP, PASSWORD, PROJECTID) => {
      console.log(`${url}User_ForgetPassword_VerifyOTP?USEREMAIL=${USEREMAIL}&OTP=${OTP}&PASSWORD=${PASSWORD}&PROJECTID=${PROJECTID}`)
      const response = await fetch(
        `${url}User_ForgetPassword_VerifyOTP?USEREMAIL=${USEREMAIL}&OTP=${OTP}&PASSWORD=${PASSWORD}&PROJECTID=${PROJECTID}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch({
          type: 'FORGOTPASSWORD',
        });
        return JSON.parse(data.ReturnData)[0];
      }
      return false;
    }, [url]);

  // VERIFY OTP
  const verifyOTP = useCallback(async (email, userId, token, otp, type, PROJECTID) => {
    const response = await fetch(
      `${url}User_Register_VerifyOTP?USEREMAIL=${email}&USERID=${userId}&TOKEN=${token}&OTP=${otp}&TYPE=${type}&PROJECTID=${PROJECTID}`
    )
    const json = await response.json();
    const data = JSON.parse(json)[0];
    if (data && data.ReturnVal === 1) {
      setUser(data.ReturnData);
      dispatch({
        type: 'REGISTER',
        payload: {
          user: JSON.parse(data.ReturnData)[0],
        },
      });
      return true;
    }
    return false;
  }, [url]);

  // LOGOUT
  const logout = useCallback(() => {
    setSession(null);
    setUser(null);
    dispatch({
      type: 'LOGOUT',
    });
  }, []);

  // SET STATE USER
  const setStateUser = useCallback((data) => {
    setUser(JSON.stringify(data));
    dispatch({
      type: 'UPDATE_PROFILE',
      payload: {
        user: data[0],
      },
    });
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      page: state.page,
      requestOtp: state.requestOtp,
      requestPWOtp: state.requestPWOtp,
      method: 'jwt',
      login,
      register,
      forgotPassword,
      verifyForgotPasswordOTP,
      logout,
      googleLogin,
      setStateUser,
      verifyOTP,
    }),
    [state.isAuthenticated, state.isInitialized, state.user, state.page, state.requestOtp, state.requestPWOtp, login, logout, register, forgotPassword, verifyForgotPasswordOTP, googleLogin, setStateUser, verifyOTP]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
