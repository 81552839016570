import sum from 'lodash/sum';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { devServer } from '../globalSettings';
import uploader from '../../utils/imgUploader';
import { ServerConfiguration } from '../../utils/serverConfig';

// ----------------------------------------------------------------------
const url = ServerConfiguration.testingServerUrl;
const PROJECTID = 1;

const initialState = {
  isLoading: false,
  error: null,
  participant: [],
  participantAction:[],

};

const slice = createSlice({
  name: 'participant',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CONFERENCE EVENTS 

    viewParticipantSuccess(state, action) {
      state.isLoading = false;
      state.participant = action.payload;
    },

    addParticipantSuccess(state, action) {
      state.isLoading = false;
      state.participantAction = action.payload;
    },

    updateParticipantSuccess(state, action) {
      state.isLoading = false;
      state.participantAction = action.payload;
    },

    deleteParticipantSuccess(state, action) {
      state.isLoading = false;
      state.participantAction = action.payload;
    },
  
    clearParticipantDataSuccess(state, action) {
      state.isLoading = false;
      state.participantAction = [];
    },
  
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getConferenceEventsSuccess,
} = slice.actions;

// ----------------------------------------------------------------------



export function viewParticipant(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await fetch(
        `${url}Conference_ViewConferenceParticipant?CONFERENCEID=${id}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.viewParticipantSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addParticipant(id, userId, attendingStatusId,packageId, paymentBankingInfo, paymentReferenceNo, submissionInd, createdBy) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await fetch(
        `${url}Conference_AddConferenceParticipant?CONFERENCEID=${id}&USERID=${userId}&ATTENDINGSTATUSID=${attendingStatusId}&CONFERENCEPACKAGEID=${packageId}&PAYMENTATTACHMENT=${paymentBankingInfo}&PAYMENTREFENCENO=${paymentReferenceNo}&CONFERENCEPAPERSUBMISSIONIND=${submissionInd}&CREATEDBY=${createdBy}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updateParticipantSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function updateParticipant(id, participantId, userId, fullname, gender, institution, contact, email, countryId, attendingStatusId, packageId, bankingInfo, referenceInfo, submissionInd, modifyBy ) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await fetch(
        `${url}Conference_UpdateConferenceParticipant?CONFERENCEID=${id}&USERID=${userId}&CONFERENCEPARTICIPANTID=${participantId}&USERFULLNAME=${fullname}&USERGENDER=${gender}&USERINSTITUITION=${institution}&USERCONTACT=${contact}&USEREMAIL=${email}&USERCOUNTRYID=${countryId}&ATTENDINGSTATUSID=${attendingStatusId}&CONFERENCEPACKAGEID=${packageId}&PAYMENTATTACHMENT=${bankingInfo}&PAYMENTREFENCENO=${referenceInfo}&CONFERENCEPAPERSUBMISSIONIND=${submissionInd}&MODIFIEDBY=${modifyBy}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updateParticipantSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteParticipant(id, modifyBy) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Conference_DeleteConferenceParticipant?CONFERENCEID=${id}&MODIFIEDBY=${modifyBy}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.deleteParticipantSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearParticipantData() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
        dispatch(slice.actions.clearParticipantDataSuccess());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
